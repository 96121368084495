import React, { useContext, useEffect, useState } from "react";
import styles from "./SchoolOfBusinessNew.module.scss";
import HeaderContext from "../../context/Context-header";
import MetaDataContext from "../../context/MetaDataContext";
import MetaData from "../../helpers/MetaData";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import studentsImage from "../../assets/images/acedemic-programmmes-school-of-business/studentImage1.png";
import XatLogo from "../../assets/images/acedemic-programmmes-school-of-business/xatLogoNew.png";
import GmatLogo from "../../assets/images/acedemic-programmmes-school-of-business/GmatLogoNew.png";
import GreLogo from "../../assets/images/acedemic-programmmes-school-of-business/GreLogoNew.png";
import Icon from "../../Layout/icons/icons";
import studentGroup from "../../assets/images/acedemic-programmmes-school-of-business/studentsPgdgm.png";
import RelatedCourses from "../CommonComponent/RelatedCourses";
import studentImage2 from "../../assets/images/acedemic-programmmes-school-of-business/studImage2.png";
import studProfile from "../../assets/images/acedemic-programmmes-school-of-business/userImg.png";
import PlacementCommitteeMailIcon from "../../assets/icons/icon-mail.svg";
import PlacementCommitteePhoneIcon from "../../assets/icons/icon-phone.svg";
import LinkedInImg from "../../assets/images/career-transformation/linked-in-black.png";
import BatchProfile from "../../assets/images/acedemic-programmmes-school-of-business/batchProfilePgdgm.png";
import fetchData from "../../utils/WebService";
import { FullLoader } from "../../components/Loading/loader";

import { Helmet } from "react-helmet";

const SchoolOfBusinessNew = () => {
  const { HeaderComponent } = useContext(HeaderContext);
  const metaInfo = useContext(MetaDataContext);
  const [pgdgmContent, setpgdgmContent] = useState([]);
  const [webContent, setWebContent] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    const fetchDataAsync = async () => {
      setIsloading(true);
      const newData = await fetchData("xlri", "pgdgm");
      if (newData) {
        const newArray = newData?.map((item) => {
          const fields = item.fields.reduce((acc, field) => {
            acc[field.fieldName] = field.value;
            return acc;
          }, {});

          return { _id: item._id, ...fields };
        });
        setWebContent(newArray);
        setIsloading(false);
      }
    };
    fetchDataAsync();
  }, []);
  const navigateToPlacement = () => {
    window.open("/placement-overview", "_blank");
  };
  useEffect(() => {
    const fetchDataAsync = async () => {
      setIsloading(true);
      const newData = await fetchData("xlri", "school-of-business-pgdgm");
      if (newData) {
        setpgdgmContent(newData);
        setIsloading(false);
      }
    };
    fetchDataAsync();
  }, []);

  return (
    <>
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-TQ7BSNQX');
              `}
        </script>
        {/* End Google Tag Manager */}

        {/* Google Tag Manager (noscript) */}
        <noscript>{`
              <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQ7BSNQX"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}</noscript>
        {/* End Google Tag Manager (noscript) */}
      </Helmet>
    <div>
      <MetaData metaData={metaInfo} />
      <HeaderComponent />
      <BreadCrumb />
      {isLoading && <FullLoader />}
      <div className={styles.sectionOne}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>{pgdgmContent?.Pgdgm_Heading}</h2>
              <div className="row">
                <div className="col-3">
                  <img src={pgdgmContent?.Pgdgm_Logo1} alt="XAT" />
                </div>
                <div className="col-4">
                  <img src={pgdgmContent?.Pgdgm_Logo2} alt="GMAT" />
                </div>
                <div className="col-4">
                  <img src={pgdgmContent?.Pgdgm_Logo3} alt="ETS GRE" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-2">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: pgdgmContent?.Pgdgm_Content,
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={pgdgmContent?.Pgdgm_Img}
                alt="Postgraduate diploma in General Management (PGDGM)"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <p
                dangerouslySetInnerHTML={{
                  __html: pgdgmContent?.Pgdgm_Content2,
                }}
              ></p>
            </div>
          </div>
          <div className="col-xl-12">
            <div className={`mt-1 d-xl-flex d-block  ${styles.ButtonWrap}`}>
              <button
                className={`btnwrap ${styles.BtnColorBlue} ${styles.BtnNewSpace}`}
              >
                <span className="a-18">
                  <a href={pgdgmContent?.Xat_Btn} target="_blank">
                    Register for XAT & XLRI
                  </a>
                </span>
                <Icon icon="arrow-right-with-cut" size={10} color="#B2C950" />
              </button>
              <button
                className={`btnwrap ${styles.BtnColorBlue} ${styles.BtnNewSpace}`}
              >
                <span className="a-18">
                  <a href={pgdgmContent?.Gmat_Btn} target="_blank">
                    Register through GMAT/GRE
                  </a>
                </span>
                <Icon icon="arrow-right-with-cut" size={10} color="#B2C950" />
              </button>
            </div>
            <br />
          </div>
          <div className="col-xl-12">
            <img src={pgdgmContent?.Pgdgm_Studentgrp_Img} />
          </div>
        </div>
      </div>

      <div className={styles.sectionTwo}>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className={styles.mainTitle}>
                <h2>{pgdgmContent?.Pgdgm_Section2_Heading}</h2>
              </div>
            </div>
            <div className={`col-md-7 ${styles.mainContent}`}>
              <p
                dangerouslySetInnerHTML={{
                  __html: pgdgmContent?.Pgdgm_Section2_Content,
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.sectionThree}>
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className={styles.tabMainSec}>
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    {webContent?.map((obj, idx) => (
                      <button
                        className={
                          activeTab == idx ? "nav-link active" : "nav-link"
                        }
                        id={`nav-${idx}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#nav-${idx}`}
                        type="button"
                        role="tab"
                        aria-controls={`nav-${idx}`}
                        aria-selected={activeTab == idx ? "true" : "false"}
                        onClick={() => {
                          if (idx === 3) {
                            const content = obj?.Pgdgm_Content;
                            const urlMatch = content.match(/href="([^"]+)"/);
                            const url = urlMatch ? urlMatch[1] : null;

                            if (url) {
                              window.location.href = url; // Open the link on the same page
                            }
                          }
                        }}
                      >
                        {obj?.Pgdgm_Accordian1}
                      </button>
                    ))}
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  {webContent?.map((obj, idx) => (
                    <div
                      className={
                        activeTab == idx
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id={`nav-${idx}`}
                      role="tabpanel"
                      aria-labelledby={`nav-${idx}-tab`}
                      tabIndex="0"
                    >
                      <div>
                        {idx !== 3 && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: obj?.Pgdgm_Content,
                            }}
                          ></p>
                        )}
                      </div>
                      {idx === 0 && (
                        <div className={styles.ambassdorContainer}>
                          <section className={styles.CardSection}>
                            <div className="container">
                              <h1>Campus Ambassadors</h1>
                              <div className={styles.SliderContent}>
                                <ul>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/simarjeet1/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Simarjeet Singh Dua</h2>

                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9891311132</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22043@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/surbhitp"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Surbhit Parandiyal</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8093676974</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>G22108@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/1ambharath"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Bharath R</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 7696610609</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>G22013@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/sanjeevani-312887121"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Sanjeevani</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8545957829</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>G22036@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/amit-sunar"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Amit Sunar</h2>

                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9643889181</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22004@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/kajalkumari04/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Kajal Kumari</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 7873709563</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22022@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/sumit-mudgal-ba0228a0/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Sumit Mudgal</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8800335407</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22106@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/vivek-bhagat-bb1685ab"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Vivek Kumar</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8750974630</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22112@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/nishit-rastogi-b751b3180/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Nishit Rastogi</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8460824002</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22028@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/dilpreet-kaur-112090145/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Dilpreet Kaur</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8447541994</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22073@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/vijayankesh-bariar"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Vijayankesh Barier</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8116366695</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22054@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/jeetu-mohan-926ab020/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Jeetu Mohan</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8007446664</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22078@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/pooja-mahindrakar"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Pooja Mahindrakar</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 7016774168</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22030@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/akshayrmali"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Akshay Mali</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9085293321</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22061@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/jyoti-panda"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Jyoti Panda</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 7749842607</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22079@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/kumgoutam"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Kumar Goutam</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9900486995</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22023@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/ankeet-rai"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Ankeet Rai</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9975180442</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22063@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/gagan-a-bajaj"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Gagan Bajaj</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8087125009</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22018@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/saiprasanth-bandaru"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>
                                          Bandaru Kanaka Siva Ram Sai Prasanth
                                        </h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9603548663</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22092@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/aparajita-chaudhuri"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Aparajita Chaudhuri</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 7397310182</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22065@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              {/* <div className={styles.LoadStyle}>
                          <a className="linkWrapper" href="/">
                            <span className="a-18">Load More</span>
                            <Icon
                              icon="arrow-right-with-cut"
                              size={10}
                              color="#B2C950"
                            />
                          </a>
                        </div> */}
                            </div>
                          </section>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.tabMainSecMobile}>
                <div className={styles.researchAccordionBgSec1}>
                  <div
                    className="accordion accordionResearch"
                    id="accordionExample"
                  >
                    {webContent?.map((obj, idx) => (
                      <div className="accordion-item">
                        <h2 className="accordion-header" id={`heading${idx}`}>
                          <button
                            className={
                              activeTab == idx
                                ? "accordion-button"
                                : "accordion-button collapsed"
                            }
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${idx}`}
                            aria-expanded={activeTab == idx ? "true" : "false"}
                            aria-controls={`collapse${idx}`}
                          >
                            {obj?.Pgdgm_Accordian1}
                          </button>
                        </h2>
                        <div
                          id={`collapse${idx}`}
                          className={
                            activeTab == idx
                              ? "accordion-collapse collapse show"
                              : "accordion-collapse collapse"
                          }
                          aria-labelledby={`heading${idx}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: obj?.Pgdgm_Content,
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        {" "}
        <RelatedCourses />
      </div>
      </div>
    </>
  );
};

export default SchoolOfBusinessNew;
