import React from "react";
import loadingImage from "../../assets/images/loading-image/loadingImg.gif";

const styles = {
  fullLoader: {
    wrapper: {
      zIndex: 1,
      position: "fixed",
      left: 0,
      top: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#fffff",
      LoadingImg: {
        textAlign: "center",
      },
    },
  },
};

export const FullLoader = () => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={styles.fullLoader.wrapper}
  >
    <div className={styles.LoadingImg}>
      <img src={loadingImage} alt="Loading..." />
    </div>
  </div>
);
