import React from "react";
import breadCrumb from "./BreadCrumb.module.scss";
import { useLocation, Link } from "react-router-dom";

const BreadCrumb = () => {
  const location = useLocation();

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb.trim() !== "")
    .map((crumb, index, array) => {
      // if (/^[a-zA-Z0-9]+$/.test(crumb)) {
      //   return null;
      // }

      const currentLink =
        `/${array.slice(0, index + 1).join("/")}` === "/aboutus-careerdetail"
          ? "/about/careers-opportunities"
          : `/${array.slice(0, index + 1).join("/")}`;


      return (
        <li className={breadCrumb.crumb} key={crumb}>
          <Link to={currentLink}>{crumb}</Link>
        </li>
      );
    });
  crumbs.unshift(
    <li className={breadCrumb.crumb} key="home">
      <Link to="/">home</Link>
    </li>
  );

  return (
    <>
      <section className={breadCrumb.breadCrumbMain}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <ul className={breadCrumb.breadcrumbList}>{crumbs}</ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BreadCrumb;
